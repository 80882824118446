import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BackgroundImage from '../../../images/sygnet_yetico.png';
import { useNavigate } from 'react-router-dom';
import { API_PATH } from '../../../../Variables';

function CompetitionRulesSection() {
    const navigate = useNavigate();
    const [fileUploaded, setFileUploaded] = useState(null); // State to store file upload status
    const [loading, setLoading] = useState(true); // State to manage loading

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user?.id;

    useEffect(() => {
        // Fetch file upload status when the component mounts
        const fetchFileUploadStatus = async () => {
            try {
                const response = await axios.get(`${API_PATH}/user/${userId}/file-upload-status`);
                setFileUploaded(response.data.file_uploaded);
            } catch (error) {
                console.error('Error fetching file upload status:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFileUploadStatus();
    }, []);

    const handleClickApplication = () => {
        navigate('/application'); // Navigate to the link destination
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    }

    return (
        <section className="competition-rules" id='competition-rules'>
            <div className="competition-rules__inner">
                <h2 className="competition-rules__title">Zasady konkursu</h2>
                <div className="competition-rules__wrapper">
                    <p>1. Musisz być zarejestrowany na platformie akcji Hi Yetico!</p>
                    <p>2. Za pomocą platformy prześlij swój projekt jeśli jest w formie elektronicznej lub jego zdjęcie, jeśli wykonałeś go w formie fizycznej. Pamiętaj, że zdjęcie musi być wyraźna i w wysokiej rozdzielczości.</p>
                    <p>3. Pamiętaj o tym, aby umieścić w swoim projekcie logo Yetico.</p>
                    <p>4. Możesz zgłosić tylko jeden projekt konkursowy.</p>
                    <p>5. Praca musi być oryginalna i nie naruszać praw autorskich innych osób.</p>
                    <p>6. Puść wodze fantazji! Możesz przygotować zgłoszenie w dowolnej formie - filmik, animacja, zdjęcie, kolaż, obraz, wiersz, piosenka, grafika, makieta.</p>
                    <p>7. Jury konkursowe ocenia kreatywność, oryginalność oraz związek z tematem.</p>
                    <p>8. Jury konkursowe wyłania <strong>dwóch</strong> zwycięzców.</p>
                    <p><strong>Prześlij swoje zgłoszenie do<br />11 sierpnia 2024 r.</strong></p>
                    <p><strong>Zwycięzców wyłonimy<br />do 18 sierpnia 2024 r.</strong></p>
                    <p>Termin wycieczki: 30.09-07.10.2024 r.</p>
                    <p className='blue-text'>Wyjazd zorganizowany<br />z Partnerem handlowym Yetico, firmą FINNFOAM</p>
                    <p>Powodzenia!</p>
                </div>
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    !fileUploaded && (
                        <button className='competition-rules__button' onClick={handleClickApplication}>Wysyłam zgłoszenie</button>
                    )
                )}

                {fileUploaded ? (
                    <p className='competition-rules__file-uploaded'>Zgłoszenie zostało wysłane</p>
                ) : null}
                
                <img src={BackgroundImage} alt="Logo Yetico" className="section-rules__image-bg competition-rules__image_bg" />
            </div>
        </section>
    );
}

export default CompetitionRulesSection;