import PageTitle from '../../../components/main/PageTitle';
import Header from '../../../components/header/Header';
import { NavLink } from 'react-router-dom';

import Image1 from '../../../images/produkt_1_desktop_plyty eps.png';
import Image2 from '../../../images/produkt_2_desktop_plyty xps.png';
import Image3 from '../../../images/produkt_3_desktop_styrodur.png';
import Image4 from '../../../images/produkt_4_desktop_piany.png';
import Image5 from '../../../images/produkt_5_desktop_kleje.png';
import Image6 from '../../../images/produkt_6_desktop_siatka.png';
import Image7 from '../../../images/produkt_7_desktop_zaslepki.png';

function Main() {
    return (
      <section className="sale-products">
        <PageTitle title="Produkty promocyjne" />
        <Header />
        <div className='sale-products__inner'>
            <h1>Produkty objęte akcją promocyjną</h1>
            <div className='sale-products__wrapper'>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image1} alt="Product" />
                    <p>Płyty EPS</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image2} alt="Product" />
                    <p>Płyty XPS Finnfoam</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image3} alt="Product" />
                    <p>Styrodur</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image4} alt="Product" />
                    <p>Piany</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image5} alt="Product" />
                    <p>Kleje</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image6} alt="Product" />
                    <p>Siatka</p>
                </NavLink>
                <NavLink to="/sale-products" className='sale-products__link'>
                    <img src={Image7} alt="Product" />
                    <p>Zaślepki</p>
                </NavLink>
            </div>
        </div>
      </section>
    );
  }
  
export default Main;