import YeticoLogo from '../../images/logo_yetico_menu.svg';
import MobileBackground from '../../images/baner-logowanie.png';
import CorrectImg from '../../images/correct.png';
import LongArrowLeft from '../../images/long_arrow_left.png';
import { NavLink } from 'react-router-dom';
import PageTitle from '../../components/main/PageTitle';

function EmailSended() {
    return (
        <section className="home">
        <PageTitle title="Email został wysłany" />
        <div className='home__leftpanel'>
            <div className='home__panel'>
                <img className='home__background--mobile' src={MobileBackground} alt='Yetico' />
                <div className="home__header">
                    <NavLink to='/' className='home__header--logo'>
                        <img src={YeticoLogo} alt="Yetico" />
                    </NavLink>
                </div>
                <div className='home__wrapper_bottom'>
                    <div className='home__text'>
                        <h1 className='home__title'>Hi Yetico!</h1>
                        <p className='home__subtitle--bold'>Budujemy ciepłe relacje</p>
                        <p className='remindpassword__subtitle'>Akcja sprzedażowo-lojalnościowa</p>
                    </div>
                    <div className='emailsended__wrapper'>
                        <img className='emailsended__correct' src={CorrectImg} alt='Correct' />
                        <div className='home__welcome'>
                            <h2 className='emailsended__welcome_title'>Super!</h2>
                            <h2 className='emailsended__welcome_title'>Sprawdź swoją pocztę.</h2>
                            <p className='home__welcome_text'>Na podany adres e-mail wysłaliśmy link<br />do ustawienia nowego hasła.</p>
                        </div>
                        <NavLink to='/' className='backToLogin'>
                           <img src={LongArrowLeft} alt='Arrow' /> powrót do strony logowania
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  export default EmailSended;