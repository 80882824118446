import PageTitle from '../../../components/main/PageTitle';
import GarminImg from '../../../images/garmin2.png';
import AenoImg from '../../../images/aeno.png';
import FlowerImg from '../../../images/flower-bg2.png';

function Main() {
    return (
      <section className="quizzes">
        <PageTitle title="Quizy" />
        <div className="quizzes__inner">
            <h1 className='quizzes__title'>Quizy</h1>
            <p className='quizzes__subtitle'>Bierz udział w quizach i zdobywaj dodatkowe punkty lub nagrody rzeczowe. Pierwszych 50 uczestników, którzy wezmą udział w quizie 1 oraz w quizie 2, i odpowiedzą na wszystkie pytania poprawnie, otrzymają dodatkową nagrodę.</p>
            <div className='quizzes__wrapper'>
                <div className='quizzes__container'>
                    <div className='quizzes__square'>
                        <p className='quizzes__container_quiz_title'>QUIZ 1</p>
                        <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                        <p className='quizzes__container_points_award'>
                            3 pkt
                            <span className='quizzes__container_plus'>+<br /></span>
                            <span className='quizzes__container_extra_award'>NAGRODA<br />EXTRA</span>
                        </p>
                        <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                    </div>
                    <p className='quizzes__inaccessible'>Quiz dostępny od 08.07.2024</p> 
                    <button disabled className='quizzes__button'>Biorę udział</button>
                </div>
                <div className='quizzes__container'>
                    <div className='quizzes__square'>
                        <p className='quizzes__container_quiz_title'>QUIZ 2</p>
                        <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                        <p className='quizzes__container_points_award'>
                            3 pkt
                            <span className='quizzes__container_plus'>+<br /></span>
                            <span className='quizzes__container_extra_award'>NAGRODA<br />EXTRA</span>
                        </p>
                        <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                    </div>
                    <p className='quizzes__inaccessible'>Quiz dostępny od 29.07.2024</p> 
                    <button disabled className='quizzes__button'>Biorę udział</button>
                </div>
                <div className='quizzes__container'>
                    <div className='quizzes__square'>
                        <p className='quizzes__container_quiz_title'>QUIZ 3</p>
                        <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                        <p className='quizzes__container_points_award'>
                            5 pkt
                        </p>
                        <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                    </div>
                    <p className='quizzes__inaccessible'>Quiz dostępny od 19.08.2024</p> 
                    <button disabled className='quizzes__button'>Biorę udział</button>
                </div>
                <div className='quizzes__container'>
                    <div className='quizzes__square'>
                        <p className='quizzes__container_quiz_title'>QUIZ 4</p>
                        <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                        <p className='quizzes__container_points_award'>
                            5 pkt
                        </p>
                        <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                    </div>
                    <p className='quizzes__inaccessible'>Quiz dostępny od 09.09.2024</p> 
                    <button disabled className='quizzes__button'>Biorę udział</button>
                </div>
                <div className='quizzes__container'>
                    <div className='quizzes__square'>
                        <p className='quizzes__container_quiz_title'>QUIZ 5</p>
                        <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                        <p className='quizzes__container_points_award'>
                            5 pkt
                        </p>
                        <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                    </div>
                    <p className='quizzes__inaccessible'>Quiz dostępny od 30.09.2024</p> 
                    <button disabled className='quizzes__button'>Biorę udział</button>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
export default Main;