import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import YeticoLogo from '../../images/logo_yetico_menu.svg';
import MobileBackground from '../../images/baner-logowanie.png';
import PageTitle from '../../components/main/PageTitle';

function RemindPassword() {
    const navigate = useNavigate();

    const handleSendCode = async (values, { setErrors }) => {
        try {
            const response = await axios.post('https://betowen.pl/api/remind-password', values);
            if (response.data.success) {
                navigate('/email-sended');
            } else {
                setErrors({ email: 'Użytkownik o takim adresie e-mail nie istnieje' });
            }
        } catch (error) {
            setErrors({ email: 'Wystąpił błąd. Spróbuj ponownie później.' });
        }
    }

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Niepoprawny adres e-mail').required('Wymagane pole')
    });

    return (
        <section className="home">
            <PageTitle title="Przypominanie hasła" />
            <div className='home__leftpanel'>
                <div className='home__panel'>
                    <img className='home__background--mobile' src={MobileBackground} alt='Yetico' />
                    <div className="home__header">
                        <NavLink to='/' className='home__header--logo'>
                            <img src={YeticoLogo} alt="Yetico" />
                        </NavLink>
                    </div>
                    <div className='home__wrapper_bottom'>
                        <div className='home__text'>
                            <h1 className='home__title'>Hi Yetico!</h1>
                            <p className='home__subtitle--bold'>Budujemy ciepłe relacje</p>
                            <p className='remindpassword__subtitle'>Akcja sprzedażowo-lojalnościowa</p>
                        </div>
                        <div className='remindpassword__wrapper'>
                            <div className='home__welcome'>
                                <h2 className='remindpassword__welcome_title'>Zapomniałeś hasła?<br />Nic nie szkodzi.</h2>
                                <p className='home__welcome_text'>Wpisz swój adres e-mail, a my wyślemy<br />Ci link do ustawienia nowego hasła.</p>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSendCode}
                            >
                                {() => (
                                    <Form className='remindpassword__form'>
                                        <div className='home__input_wrapper'>
                                            <Field
                                                className='home__input'
                                                type='text'
                                                name='email'
                                                placeholder='Adres e-mail podany przy rejestracji'
                                            />
                                            <ErrorMessage
                                                name='email'
                                                component='div'
                                                className='home__error'
                                            />
                                        </div>
                                        <div className='home__form_button'>
                                            <button className='home__button--login' type='submit'>Wyślij kod</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RemindPassword;