import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import YeticoLogo from '../../images/logo_yetico_menu.svg';
import MobileBackground from '../../images/baner-logowanie.png';
import PageTitle from '../../components/main/PageTitle';
import { NavLink } from 'react-router-dom';

function SetNewPassword({ userId }) { // Assuming userId is passed as a prop
    const [password, setPassword] = useState('');

    const initialValues = {
        newPassword: '',
        repeatNewPassword: ''
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .required('Wymagane')
            .min(8, 'Hasło musi mieć co najmniej 8 znaków')
            .matches(/[a-z]/, 'Musi zawierać co najmniej jedną małą literę')
            .matches(/[A-Z]/, 'Musi zawierać co najmniej jedną dużą literę')
            .matches(/\d/, 'Musi zawierać co najmniej jedną cyfrę')
            .matches(/[@$!%*?&]/, 'Musi zawierać co najmniej jeden znak specjalny'),
        repeatNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Hasła muszą być takie same')
            .required('Wymagane pole')
    });

    const PasswordRequirements = ({ password }) => {
        const requirements = [
            { regex: /.{8,}/, label: 'Co najmniej 8 znaków' },
            { regex: /[a-z]/, label: 'Co najmniej jedna mała litera' },
            { regex: /[A-Z]/, label: 'Co najmniej jedna duża litera' },
            { regex: /\d/, label: 'Co najmniej jedna cyfra' },
            { regex: /[@$!%*?&]/, label: 'Co najmniej jeden znak specjalny' },
        ];

        return (
            <div className='password-requirements'>
                {requirements.map((req, index) => (
                    <div key={index} style={{ color: req.regex.test(password) ? 'green' : 'gray' }}>
                        {req.label}
                    </div>
                ))}
            </div>
        );
    };

    const handleSubmit = async (values, { setErrors }) => {
        try {
            const response = await axios.post(`https://betowen.pl/api/set-new-password/${userId}`, values);
            if (response.data.success) {
                // Handle successful password update
            } else {
                setErrors({ newPassword: response.data.message });
            }
        } catch (error) {
            setErrors({ newPassword: 'Wystąpił błąd. Spróbuj ponownie później.' });
        }
    };

    return (
        <section className="home">
            <PageTitle title="Ustaw nowe hasło" />
            <div className='home__leftpanel'>
                <div className='home__panel'>
                    <img className='home__background--mobile' src={MobileBackground} alt='Yetico' />
                    <div className="home__header">
                        <NavLink to='/' className='home__header--logo'>
                            <img src={YeticoLogo} alt="Yetico" />
                        </NavLink>
                    </div>
                    <div className='home__wrapper_bottom'>
                        <div className='home__text'>
                            <h1 className='home__title'>Hi Yetico!</h1>
                            <p className='home__subtitle--bold'>Budujemy ciepłe relacje</p>
                            <p className='remindpassword__subtitle'>Akcja sprzedażowo-lojalnościowa</p>
                        </div>
                        <div className='emailsended__wrapper'>
                            <div className='home__welcome'>
                                <h2 className='emailsended__welcome_title'>Ustaw nowe hasło<br />do Hi Yetico!</h2>
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange }) => (
                                <Form className='setnewpassword__form'>
                                    <div className='inputs-wrapper'>
                                        <div className='home__input_wrapper'>
                                            <Field
                                                className='home__input'
                                                type='password'
                                                name='newPassword'
                                                placeholder='Nowe hasło'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                            <PasswordRequirements password={values.newPassword} />
                                        </div>
                                        <div className='home__input_wrapper'>
                                            <Field
                                                className='home__input'
                                                type='password'
                                                name='repeatNewPassword'
                                                placeholder='Powtórz nowe hasło'
                                            />
                                            <ErrorMessage name='repeatNewPassword' component='div' className='home__error' />
                                        </div>
                                    </div>
                                    <div className='home__form_button'>
                                        <button className='home__button--login' type='submit'>Zapisz</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SetNewPassword;