import Main from './components/Main';
import Footer from "../../components/footer/Footer";

function Home() {
    return (
      <>
        <Main />
        <Footer />
      </>   
    );
  }
  
export default Home;