import WelcomeSection from "./components/WelcomeSection";
import CompetitionRulesSection from "./components/CompetitionRulesSection";
import Footer from "../../components/footer/Footer";

function Competition() {
    return (
      <>
        <WelcomeSection />
        <CompetitionRulesSection />
        <Footer />
      </>   
    );
  }
  
export default Competition;