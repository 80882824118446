import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoginData from './LoginData';
import Password from './Password';
import CompanyData from './CompanyData';
import PageTitle from '../../../components/main/PageTitle';
import { API_PATH } from '../../../../Variables';

function Main() {
    const userJson = sessionStorage.getItem('user');
    const user = JSON.parse(userJson);
    const userName = user.name;
    const userId = user.id;

    const [userCode, setUserCode] = useState('');

    useEffect(() => {
        const fetchUserCode = async () => {
            try {
                const response = await axios.get(`${API_PATH}/user/${userId}/code`);
                setUserCode(response.data.code);
            } catch (error) {
                console.error('Error fetching user code:', error);
            }
        };

        fetchUserCode();
    }, [userId]);

    return (
        <section className="account">
            <PageTitle title="Moje konto" />
            <div className="account__inner">
                <h1 className="account__title">Moje konto</h1>
                <div className="account__user_info">
                    <p className="account__user_name">Cześć {userName}</p>
                    <p className="account__user_points">Posiadasz: <strong>300 pkt</strong></p>
                    <p className="account__user_quizzes">Wypełnione quizy: <strong>0 z 4</strong></p>
                </div>
                <p className="account__code"><strong>Twój unikalny kod:</strong> {userCode}</p>
                <div className="account__content">
                    <LoginData />
                    <Password />
                    <CompanyData />
                </div>
            </div>
        </section>
    );
}

export default Main;