import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import CompanyData from '../account/components/CompanyData';
import { API_PATH } from '../../../Variables';
import { useNavigate } from 'react-router-dom';
import UploadIco from '../../images/upload_ico.svg';

Modal.setAppElement('#root');

function Home() {
    const [file, setFile] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user?.id;
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileRemove = () => {
        setFile(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            alert('Please select a file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);

        try {
            const response = await axios.post(`${API_PATH}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error uploading file', error);
            alert('Error uploading file');
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        navigate('/competition');
    };

    return (
        <>
            <Header />
            <section className="application">
                <div className='application__inner'>
                    <h1 className='application__title'>Zgłoszenie konkursowe</h1>
                    <div className='application__wrapper'>
                        <div className='application__col1'>
                            <form onSubmit={handleSubmit} className='application__form'>
                                <input 
                                    type="file" 
                                    id='file'
                                    accept=".jpg,.png,.mp4,.mp3" 
                                    onChange={handleFileChange} 
                                    className='application__file-input'
                                />
                                <label htmlFor="file" className='application__file-label'>
                                    {file ? (
                                        <div className='application__file-info'>
                                            <span>{file.name}</span>
                                            <button type="button" onClick={handleFileRemove} className='application__file-remove'>✖</button>
                                        </div>
                                    ) : (
                                        <>
                                            <img src={UploadIco} alt='upload' className='application__upload_ico' />
                                            <div className='application__label_text'>wgraj plik</div>
                                            <div className='application__label_text'>max. 50 MB</div>
                                            <div className='application__label_text'>(dopuszczalne formaty: jpg, png, mp4, mp3)</div>
                                        </>
                                    )}
                                </label>
                                <button type="submit" className='application__submit'>Wysyłam zgłoszenie</button>
                            </form>
                        </div>
                        <div className='application__col2'>
                            <CompanyData />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Notification"
                className="Modal"
                overlayClassName="Overlay"
            >
                <p><strong>Uadło się!</strong></p>
                <p>Zgłoszenie zostało wysłane.</p>
                <p>Zwycięzców wyłonimy<br />18 sierpnia 2024 r.</p>
                <p>Ze zwycięzcami konkursu skontaktujemy się mailowo lub telefonicznie.</p>
                <button onClick={closeModal}>ok</button>
            </Modal>
        </>
    );
}

export default Home;