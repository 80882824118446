import PageTitle from '../../../components/main/PageTitle';
import { NavLink } from 'react-router-dom';
import Image1 from '../../../images/nagroda_1_moje konto_desktop.png';
import Image2 from '../../../images/nagroda_2_moje konto_desktop.png';

function Main() {
    return (
      <section className='awards-page'>
        <PageTitle title="Nagrody" />
        <div className='awards-page__information'>zamawianie nagród od 21.10.2024</div>
        <div className='awards-page__inner'>
            <h1 className='awards-page__title'>Nagrody</h1>
            <p className='awards-page__subtitle'>Przybij piątkę!<br />  5 progów zakupowych.</p>
            <div className='awards-page__warning'>
                <div className='awards-page__warning_icon'>!</div>
                <p>W przypadku niedostępności produktu zaproponujemy<br />model o podobnych parametrach.</p>
            </div>
            <div className='awards-page__main_wrapper'>
                <div className='awards-page__container_wrapper' id='awards30'>
                    <div className='awards-page__container_text'>
                        <p className='awards-page__container_points'>od 30 pkt</p>
                        <p className='awards-page__container_title'>PRÓG STARTOWY</p>
                        <div className='awards-page__container_awards_wrapper'>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='awards-page__container_wrapper' id='awards70'>
                    <div className='awards-page__container_text'>
                        <p className='awards-page__container_points'>od 70 pkt</p>
                        <p className='awards-page__container_title'>PRÓG ZAAWANSOWANY</p>
                        <div className='awards-page__container_awards_wrapper'>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='awards-page__container_wrapper' id='awards150'>
                    <div className='awards-page__container_text'>
                        <p className='awards-page__container_points'>od 150 pkt</p>
                        <p className='awards-page__container_title'>PRÓG PREMIUM</p>
                        <div className='awards-page__container_awards_wrapper'>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='awards-page__container_wrapper' id='awards250'>
                    <div className='awards-page__container_text'>
                        <p className='awards-page__container_points'>od 250 pkt</p>
                        <p className='awards-page__container_title'>PRÓG EKSPANSYWNY</p>
                        <div className='awards-page__container_awards_wrapper'>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image2} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='awards-page__container_wrapper' id='awards400'>
                    <div className='awards-page__container_text'>
                        <p className='awards-page__container_points'>od 400 pkt</p>
                        <p className='awards-page__container_title'>PRÓG MISTRZOWSKI</p>
                        <div className='awards-page__container_awards_wrapper'>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                            <NavLink to="/awards" className='awards-page__container_link'>
                                <img src={Image1} alt="Award" />
                                <button className='awards-page__container_button'>zobacz szczegóły</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section> 
    );
  }
  
export default Main;