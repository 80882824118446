import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './assets/styles/main.scss';
import React, { useEffect } from 'react';
import Home from './assets/pages/login/Home';
import RemindPassword from './assets/pages/login/RemindPassword';
import EmailSended from './assets/pages/login/EmailSended';
import SetNewPassword from './assets/pages/login/SetNewPassword';
import Register from './assets/pages/register/Register';
import HomeLogged from './assets/pages/home/Home';
import Competition from './assets/pages/competition/Competition';
import HomeSalesProducts from './assets/pages/sale-products/Home';
import HomeAwards from './assets/pages/awards/Home';
import HomeQuizzes from './assets/pages/quizzes/Home';
import HomeAccount from './assets/pages/account/Home';
import { AuthProvider } from './assets/pages/auth/AuthContext';
import ProtectedRoute from './assets/pages/auth/ProtectedRoute';
import HomeApplication from './assets/pages/application/Home';

function App() {

  useEffect(() => {
    document.documentElement.style.backgroundColor = 'white';
    document.documentElement.style.color = 'black';
    document.body.style.backgroundColor = 'white';
    document.body.style.color = 'black';
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* BEZ ZALOGOWANIA */}
            <Route path="/" element={<Home />} />
            <Route path="/remind-password" element={<RemindPassword />} />
            <Route path="/email-sended" element={<EmailSended />} />
            <Route path="/set-new-password" element={<SetNewPassword />} />
            <Route path="/register" element={<Register />} />

            {/* DLA ZALOGOWANYCH */}
            <Route path="/home" element={<ProtectedRoute element={<HomeLogged />} />} />
            <Route path="/competition" element={<ProtectedRoute element={<Competition />} />} />
            <Route path="/sale-products" element={<ProtectedRoute element={<HomeSalesProducts />} />} />
            <Route path="/awards" element={<ProtectedRoute element={<HomeAwards />} />} />
            <Route path="/quizzes" element={<ProtectedRoute element={<HomeQuizzes />} />} />
            <Route path="/account" element={<ProtectedRoute element={<HomeAccount />} />} />
            <Route path="/application" element={<ProtectedRoute element={<HomeApplication />} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;