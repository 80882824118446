import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Main from './components/Main';

function Home() {
    return (
      <>
        <Header />
        <Main />
        <Footer />
      </>   
    );
  }
  
export default Home;