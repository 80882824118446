function CompanyData() {

    const userJson = sessionStorage.getItem('user');
    const user = JSON.parse(userJson);

    const userName = user.name;
    const userLastname = user.lastname;
    const userPhone = user.phone_number;
    const userNip = user.nip;
    const userCompanyName = user.company_name;
    const userStreet = user.street_name;
    const userZipCode = user.zip_code;
    const userCity = user.city;

    return (    
        <div className="account__content_data">
            <p className='account__content_title'>Dane firmy</p>
            <div className='account__content_wrapper_data'>
                <p>{userName}</p>
                <p>{userLastname}</p>
                <p>{userPhone}</p>
                <p>NIP {userNip}</p>
                <p>{userCompanyName}</p>
                <p>ul. {userStreet}</p>
                <p>{userZipCode}</p>
                <p>{userCity}</p>
            </div>
        </div>
    );
  }
  
export default CompanyData;