import TripImg from '../../../images/konkurs_img.png';
import StrickerImg from '../../../images/sticker.svg';

function TripSection() {
    return (
     <section className="trip">
        <div className="trip__inner">
            <div className="trip__wrapper">
                <div className="trip__column">
                    <div className='trip__column_container'>
                        <img className='trip__column_img1' src={TripImg} alt="Wycieczka" />
                        <div className='trip__column_frame'></div>
                    </div> 
                </div>
                <div className="trip__column2">
                    <div className='trip__wrapper_img'>
                        <img className='trip__column_img2' src={StrickerImg} alt="Naklejka" />
                    </div>
                    <h2 className="trip__title">Wyspy Kanaryjskie<br />czekają na Ciebie!</h2>
                    <p className="trip__description">Weź udział w konkursie kreatywnymni zawalcz o extra nagrodę! Wyjazd z osobą towarzyszącą na Fuerteventurę!</p>
                    <button className="trip__button">Walczę o ekstra nagrodę</button>
                </div>
            </div>
        </div>
     </section>
    );
  }
  
export default TripSection;