import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ChangeIcon from '../../../images/zmiana_danych_ico.svg';

const passwordValidationSchema = Yup.string()
    .required('Wymagane pole')
    .min(8, 'Hasło musi mieć co najmniej 8 znaków')
    .matches(/[a-z]/, 'Hasło musi zawierać co najmniej jedną małą literę')
    .matches(/[A-Z]/, 'Hasło musi zawierać co najmniej jedną dużą literę')
    .matches(/[0-9]/, 'Hasło musi zawierać co najmniej jedną cyfrę')
    .matches(/[^a-zA-Z0-9]/, 'Hasło musi zawierać co najmniej jeden znak specjalny');

function Password() {
    const [isEditable, setIsEditable] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEditClick = () => {
        setIsEditable(!isEditable);
        setSuccessMessage('');
    };

    const handleSubmit = async (values, { setErrors, resetForm }) => {
        const userJson = sessionStorage.getItem('user');
        const user = JSON.parse(userJson);
        const userId = user.id;

        // Sprawdź, czy nowe hasło nie jest takie samo jak stare
        if (values.oldpassword === values.newpassword) {
            setErrors({ newpassword: 'Nowe hasło nie może być takie samo jak stare hasło' });
            return;
        }

        try {
            setLoading(true);
            await axios.put(`https://betowen.pl/api/user/${userId}/change-password`, {
                oldpassword: values.oldpassword,
                newpassword: values.newpassword,
                newpassword_confirmation: values.repeatpassword,
            });
            setSuccessMessage('Hasło zostało zmienione.');
            resetForm();
            setIsEditable(false);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors({ oldpassword: 'Nieprawidłowe stare hasło' });
                resetForm({
                    values: {
                        oldpassword: '',
                        newpassword: '',
                        repeatpassword: '',
                    },
                });
                setLoading(false);
            } else {
                console.error('Error updating password:', error);
                setLoading(false);
            }
        }
    };

    return (
        <div className="account__content_data">
            <p className='account__content_title'>Hasło</p>
            <Formik
                initialValues={{
                    oldpassword: '',
                    newpassword: '',
                    repeatpassword: '',
                }}
                validationSchema={
                    Yup.object({
                        oldpassword: Yup.string().required('Wymagane pole'),
                        newpassword: passwordValidationSchema,
                        repeatpassword: Yup.string()
                            .oneOf([Yup.ref('newpassword'), null], 'Hasła muszą być takie same')
                            .required('Wymagane pole'),
                    })
                }
                onSubmit={handleSubmit}
            >
                {({ resetForm }) => (
                    <Form className='account__form'>
                        <div className='account__input_wrapper'>
                            <Field className='account__input' type='password' name='oldpassword' placeholder='Stare hasło' disabled={!isEditable} />
                            <ErrorMessage name='oldpassword' component='div' className='account__error' />
                        </div>
                        <div className='account__input_wrapper'>
                            <Field className='account__input' type='password' name='newpassword' placeholder='Nowe hasło' disabled={!isEditable} />
                            <ErrorMessage name='newpassword' component='div' className='account__error' />
                        </div>
                        <div className='account__input_wrapper'>
                            <Field className='account__input' type='password' name='repeatpassword' placeholder='Powtórz hasło' disabled={!isEditable} />
                            <ErrorMessage name='repeatpassword' component='div' className='account__error' />
                        </div>
                        <div className='account__buttons_wrapper'>
                            <button type='button' className='account__form_change_data_btn' onClick={handleEditClick}>
                                <img src={ChangeIcon} alt="" /> {isEditable ? 'dezaktywuj zmiany' : 'zmień hasło'}
                            </button>
                            <button type='submit' className='account__form_submit' disabled={!isEditable}>{loading ? 'zapisuję...' : 'zapisz'}</button>
                        </div>
                        {successMessage && <p className='account__success'>{successMessage}</p>}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Password;