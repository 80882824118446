import PageTitle from '../../../components/main/PageTitle';
import MobileBackground from '../../../images/baner-main.png';
import Header from '../../../components/header/Header';
import ScrollMouse from '../../../images/scroll_animation_icon.svg';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function WelcomeSection() {

    const ScrollToSection = () => {
        const { hash } = useLocation();
      
        useEffect(() => {
          if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, [hash]);
      
        return null;
    };

    const navigate = useNavigate();

    const handleScrollClick = () => {
        navigate('/home#actions-rules');
    }

    const handleCheckAward = () => {
        navigate('/awards'); // Navigate to the link destination
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    }

    return (
      <section className="homelogged">
        <PageTitle title="Strona główna" />
        <ScrollToSection />
        <Header />
        <div className='homelogged__leftpanel'>
            <div className='homelogged__panel'>
                <img className='homelogged__background--mobile' src={MobileBackground} alt='Yetico' />
                <div className='homelogged__wrapper_bottom'>
                    <div className='homelogged__text'>
                        <h1 className='homelogged__title'>Hi Yetico!</h1>
                        <p className='homelogged__subtitle--bold'>Budujemy ciepłe relacje</p>
                        <p className='homelogged__subtitle'>Akcja sprzedażowo-lojalnościowa</p>
                        <p className='home__subtitle--mobile'>Hi Yetico!</p>
                    </div>
                    <div className='homelogged__text2'>
                        <p className='homelogged__text2-2'>Super, że jesteś tutaj i chcesz czerpać<br className='home-br-hide' />korzyści ze współpracy<br className='home-br-show' /> z Yetico.</p>
                        <br />
                        <p className='homelogged__text2-2'><strong>Odblokuj kolejne progi zakupowe</strong><br />i zdobywaj nagrody.</p>
                    </div>
                    <button className='homelogged__button2' onClick={handleCheckAward}>Sprawdzam nagrody</button>
                </div>
            </div>
            <button className='homelogged__scroll' onClick={handleScrollClick}>
                <img className='homelogged__scroll--icon' src={ScrollMouse} alt='Scroll' />
                <p className='homelogged__scroll--text'>dowiedz się więcej</p>
            </button>
        </div>
      </section>
    );
  }
  
  export default WelcomeSection;