import { useNavigate } from "react-router-dom";

function ExtraPoints() {

    const navigate = useNavigate();

    const handleGoToQuizzes = () => {
        navigate('/quizzes'); // Navigate to the link destination
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
      }


    return (
     <section className="extra-points">
        <div className="extra-points__inner">
            <div className="extra-points__container">
                <h2>ZDOBĄDŹ DODATKOWE PUNKTY!</h2>
                <p>Bierz udział w quizach i zdobywaj dodatkowe punkty.</p>
                <button onClick={handleGoToQuizzes}>Biorę udział</button>
            </div>
        </div>
     </section>
    );
  }
  
export default ExtraPoints;