import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import YeticoLogo from '../../images/logo_yetico_menu.svg';
import DoubleArrow from '../../images/double_arrow_right.svg';
import PageTitle from '../../components/main/PageTitle';
import { API_PATH } from '../../../Variables';

function Register() {
    const [showMoreContent, setShowMoreContent] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleShowMoreContent = (e) => {
        e.preventDefault();
        setShowMoreContent(!showMoreContent);
    }

    const initialValues = {
        user_type: 'owner',
        name: '',
        lastname: '',
        password: '',
        password_confirmation: '',
        code: '',
        email: '',
        phone_number: '',
        nip: '',
        company_name: '',
        zip_code: '',
        city: '',
        street_name: '',
        street_number: '',
        accept1: false,
        accept2: false,
        marketing: false,
        email_contact: 'yes',
        phone_contact: 'yes',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Wymagane pole'),
        lastname: Yup.string().required('Wymagane pole'),
        password: Yup.string()
        .required('Wymagane')
        .min(8, 'Hasło musi mieć co najmniej 8 znaków')
        .matches(/[a-z]/, 'Musi zawierać co najmniej jedną małą literę')
        .matches(/[A-Z]/, 'Musi zawierać co najmniej jedną dużą literę')
        .matches(/\d/, 'Musi zawierać co najmniej jedną cyfrę')
        .matches(/[@$!%*?&]/, 'Musi zawierać co najmniej jeden znak specjalny'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same').required('Wymagane pole'),
        code: Yup.string().required('Wymagane pole'),
        email: Yup.string().email('Nie poprawny adres e-mail').required('Wymagane pole'),
        phone_number: Yup.string()
        .matches(/^\d{9}$/, 'Numer telefonu musi składać się z dokładnie 9 cyfr')
        .required('Wymagane pole'),
        nip: Yup.string()
        .matches(/^\d{10}$/, 'NIP musi składać się z dokładnie 10 cyfr')
        .required('Wymagane pole'),
        company_name: Yup.string().required('Wymagane pole'),
        zip_code: Yup.string().matches(/^\d{2}-\d{3}$/, 'Poprawny format kodu pocztowego to (XX-XXX)').required('Wymagane pole'),
        city: Yup.string().required('Wymagane pole'),
        street_name: Yup.string().required('Wymagane pole'),
        street_number: Yup.string().required('Wymagane pole'),
        accept1: Yup.bool().oneOf([true], 'Wymagana zgoda'),
        accept2: Yup.bool().oneOf([true], 'Wymagana zgoda'),
    });

    const PasswordRequirements = ({ password }) => {
        const requirements = [
            { regex: /.{8,}/, label: 'Co najmniej 8 znaków' },
            { regex: /[a-z]/, label: 'Co najmniej jedna mała litera' },
            { regex: /[A-Z]/, label: 'Co najmniej jedna duża litera' },
            { regex: /\d/, label: 'Co najmniej jedna cyfra' },
            { regex: /[@$!%*?&]/, label: 'Co najmniej jeden znak specjalny' },
        ];

        return (
            <div className='password-requirements'>
                {requirements.map((req, index) => (
                    <div key={index} style={{ color: req.regex.test(password) ? 'green' : 'gray' }}>
                        {req.label}
                    </div>
                ))}
            </div>
        );
    };

    const errorTranslations = {
        'Validation failed': 'Nie poprawne dane.',
        'The email has already been taken.': 'Adres e-mail jest już zajęty.',
        'The selected code is invalid.': 'Nie prawidłowy kod zaproszenia.',
        "Code already assigned to another user": "Kod zaproszenia jest już przypisany do innego użytkownika.",
    };    

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {

        const payload = {
            ...values,
            email_contact: values.email_contact === 'yes' ? true : false,
            phone_contact: values.phone_contact === 'yes' ? true : false,
        };

        try {
            setLoading(true);
            const response = await axios.post(`${API_PATH}/register`, payload);
            navigate('/');
            setLoading(false);
        } catch (error) {
            // console.log(error.response.status);
            // console.log(error.response.data);
            if (error.response && error.response.data && error.response.data.errors) {
                const apiErrors = error.response.data.errors;
                const translatedErrors = {};
    
                Object.keys(apiErrors).forEach(key => {
                    translatedErrors[key] = apiErrors[key].map(
                        errorMsg => errorTranslations[errorMsg] || errorMsg
                    );
                });
    
                setErrors({ ...translatedErrors });
                setLoading(false);
            } else {
                setErrors({ api: 'Rejestracja nie powiodła się. Spróbuj ponownie później.' });
                setLoading(false);
            }
        } finally {
            setSubmitting(false);
            setLoading(false);
        }
    }

    return (
        <section className="register">
            <PageTitle title="Rejestracja" />
            <div className='register__inner'>
                <NavLink to='/' className='register__logo'>
                    <img src={YeticoLogo} alt="Yetico" />
                </NavLink>
                <h1 className='register__title'>Rejestracja*</h1>
                <p className='register__info'>
                    * za poprawność danych odpowiada uczestnik<br />
                    ** zgody obowiązkowe
                </p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, handleChange, isSubmitting, errors }) => (
                        <Form className='register__form'>
                            <h2 className='register__subtitle'>Biorę udział w programie<br />Hi Yetico jako:</h2>
                            <div className='register__radio_wrapper'>
                                <div className='register__radio'>
                                    <Field type='radio' name='user_type' value='owner' id='type-owner' />
                                    <label htmlFor='type-owner'>Właściciel firmy</label>
                                </div>
                                <div className='register__radio'>
                                    <Field type='radio' name='user_type' value='employee' id='type-employee' />
                                    <label htmlFor='type-employee'>Pracownik firmy</label>
                                </div>
                            </div>
                            <div className='register__form_main'>
                                <div className='register__form_column'>
                                    <h2 className='register__column_title'>Dane logowania</h2>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='name' placeholder='Imię' />
                                        <ErrorMessage name='name' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='lastname' placeholder='Nazwisko' />
                                        <ErrorMessage name='lastname' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='password' name='password' placeholder='Hasło' onChange={handleChange} />
                                        <PasswordRequirements password={values.password} />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='password' name='password_confirmation' placeholder='Powtórz Hasło' />
                                        <ErrorMessage name='password_confirmation' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='code' placeholder='Unikalny kod zaproszenia' />
                                        <ErrorMessage name='code' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='email' name='email' placeholder='Adres e-mail' />
                                        <ErrorMessage name='email' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field
                                            className='register__input'
                                            type='text'
                                            name='phone_number'
                                            placeholder='Numer telefonu'
                                            value={values.phone_number}
                                            onChange={(e) => {
                                            const value = e.target.value;
                                            // Usuwamy wszystkie znaki, które nie są cyframi
                                            const onlyNums = value.replace(/[^\d]/g, '');
                                            // Ucinamy nadmiarowe cyfry, jeśli długość przekracza 9 cyfr
                                            const trimmedNums = onlyNums.slice(0, 9);
                                            setFieldValue('phone_number', trimmedNums);
                                            }}
                                        />
                                        <ErrorMessage name='phone_number' component='div' className='register__error' />
                                    </div>
                                </div>
                                <div className='register__form_column'>
                                    <h2 className='register__column_title'>Dane siedziby firmy</h2>
                                    <div className='register__input_wrapper'>
                                        <Field
                                            className='register__input'
                                            type='text'
                                            name='nip'
                                            placeholder='NIP firmy'
                                            value={values.nip}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/[^\d]/g, ''); // Usuń wszystkie niedozwolone znaki
                                                setFieldValue('nip', input);
                                            }}
                                        />
                                        <ErrorMessage name='nip' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='company_name' placeholder='Nazwa firmy' />
                                        <ErrorMessage name='company_name' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field
                                            className='register__input'
                                            type='text'
                                            name='zip_code'
                                            placeholder='Kod pocztowy'
                                            value={values.zip_code}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/[^\d]/g, ''); // Usuń wszystkie niedozwolone znaki
                                                let formatted = '';
                                                if (input.length > 2) {
                                                    formatted = input.slice(0, 2) + '-' + input.slice(2, 5);
                                                } else {
                                                    formatted = input;
                                                }
                                                setFieldValue('zip_code', formatted);
                                            }}
                                        />
                                        <ErrorMessage name='zip_code' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='city' placeholder='Miasto' />
                                        <ErrorMessage name='city' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='street_name' placeholder='Ulica' />
                                        <ErrorMessage name='street_name' component='div' className='register__error' />
                                    </div>
                                    <div className='register__input_wrapper'>
                                        <Field className='register__input' type='text' name='street_number' placeholder='Nr budynku i lokalu' />
                                        <ErrorMessage name='street_number' component='div' className='register__error' />
                                    </div>
                                </div>
                                <div className='register__form_column'>
                                    <div className='register__checkbox_wrapper'>
                                        <Field type='checkbox' name='accept1' id='accept1' />
                                        <label htmlFor='accept1'>
                                        ** Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu powyżej w celach związanych przedmiotowoz akcją promocyjną i jej organizacją w tym w celach związanych z ustaleniem prawa danego Uczestnika do uzyskania Nagrody, wydania i doręczenia Nagrody, ogłoszenia wyników Konkursu. Przyjmuję do wiadomości, że administratorem tychże danych jest spółka LOOK AD Spółka z ograniczoną odpowiedzialnością Spółka komandytowa z siedzibą w Poznaniu (60-319), ul. Ptasia 10. Zasady funkcjonowania i warunki uczestnictwa w&nbsp;
                                    <span
                                        className={showMoreContent ? 'register__accept1_more_content' : 'register__accept1_more_content hide-more-content'}>
                                        dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis dalszy opis
                                    </span>
                                        </label>
                                        <ErrorMessage name='accept1' component='div' className='register__error' />
                                        <button className='register__button_more' onClick={handleShowMoreContent}>
                                            {showMoreContent ? 'mniej' : 'więcej'}
                                            <img className='register__double_arrow' src={DoubleArrow} alt='more' />
                                        </button>
                                    </div>
                                    <div className='register__checkbox_wrapper'>
                                        <Field type='checkbox' name='accept2' id='accept2' />
                                        <label htmlFor='accept2'>
                                        * Zapoznałem się z <a className='register__link' href='/'>Regulaminem </a>akcji propocyjnej "Hi Yetico", w tym przede wszytskim z klauzulą informacyjną dotyczącą przetwarzania danych osobowych.
                                        </label>
                                        <ErrorMessage name='accept2' component='div' className='register__error' />
                                    </div>
                                    <div className='register__checkbox_wrapper'>
                                        <Field type='checkbox' name='marketing' id='accept3' />
                                        <label htmlFor='accept3'>
                                        Wyrażam dobrowolną zgodę na otrzymywanie od Yetico......... informacji handlowych i marketingowych w szczególności o promocjach i konkursach oraz nowych i aktualnych produktach Yetico, za pośrednictwem:
                                        </label>
                                    </div>
                                    <div className='register__radio_wrapper--2'>
                                        <div></div>
                                        <div className='register__wrapper'>
                                            <p>poczty elektronicznej na podany przeze mnie adres mailowy:</p>
                                            <div className='register__radio'>
                                                <Field type='radio' name='email_contact' value='yes' id='marketing1-yes' />
                                                <label htmlFor='marketing1-yes'>TAK</label>
                                            </div>
                                            <div className='register__radio'>
                                                <Field type='radio' name='email_contact' value='no' id='marketing1-no' />
                                                <label htmlFor='marketing1-no'>NIE</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='register__radio_wrapper--2'>
                                        <div></div>
                                        <div className='register__wrapper'>
                                            <p>kontaktu telefonicznego, wiadomości sms i mms, na podany przeze mnie numer telefonu:</p>
                                            <div className='register__radio'>
                                                <Field type='radio' name='phone_contact' value='yes' id='marketing2-yes' />
                                                <label htmlFor='marketing2-yes'>TAK</label>
                                            </div>
                                            <div className='register__radio'>
                                                <Field type='radio' name='phone_contact' value='no' id='marketing2-no' />
                                                <label htmlFor='marketing2-no'>NIE</label>
                                            </div>
                                        </div>
                                    </div>
                                    {errors.api && <div className='register__error'>{errors.api}</div>}
                                    <button className='register__submit' type='submit' disabled={isSubmitting}>{loading ? 'Rejestrowanie...' : 'Zarejestruj'}</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
}

export default Register;